import store from './store'
import firebase from "firebase/app"; 

// handling all firebase auth methods centrally so that components are firebase agnostic
export default {
    install(Vue, options) {

        const auth = firebase.auth()

        auth.onAuthStateChanged(user => {
            store.dispatch("fetchUser", user);
        });

        Vue.prototype.$auth = {
            login: async (username, password) => {
                try {
                    return await auth.signInWithEmailAndPassword(username, password)
                } catch (error) {
                    console.log("error", error)
                    throw error;
                }
            },
            logout: async () => {
                return await auth.signOut()
            },
            createAccount: async (name, email, password) => {
                await auth.createUserWithEmailAndPassword(email, password)
                await auth.currentUser.updateProfile({displayName: name})
                return store.dispatch("fetchUser", auth.currentUser);
            },
            resetPassword: async (email) => {
                return await auth.sendPasswordResetEmail(email)  
            }
        }

    }
}