<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <nav class="bg-white shadow z-10 sticky">
    <div class="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex justify-between h-16">
        <div class="flex">
          <div class="-ml-2 mr-2 flex items-center md:hidden">
            <!-- Mobile menu button -->
            <button
              @click="isMenuOpen = !isMenuOpen"
              class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              aria-expanded="false"
            >
              <span class="sr-only">Open main menu</span>
              <svg
                v-show="!isMenuOpen"
                class="block h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
              <svg
                v-show="isMenuOpen"
                class="block h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div class="flex-shrink-0 flex items-center">
            <router-link :to="{ name: 'home' }">
              <img
                class="block h-8 w-auto"
                src="../assets/logo.png"
                alt="CloudEMDR"
              />
            </router-link>
          </div>
        </div>
        <div class="flex items-center hidden md:flex -mr-4">
          <div class="hidden md:ml-6 md:flex md:space-x-4 md:mr-4">
            <!-- Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" -->
            <router-link
              to="/about"
              @click.native="close"
              class="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
            >
              About
            </router-link>
            <router-link
              to="/help"
              @click.native="close"
              class="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
            >
              Help
            </router-link>
          </div>

          <div class="flex-shrink-0 space-x-4">
            <template v-if="user.loggedIn">
              <router-link
                :to="{ name: 'host', params: { id: 'new' } }"
                @click.native="close"
              >
                <button
                  type="button"
                  class="relative inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <svg
                    class="-ml-1 mr-2 h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <span>Start Remote Session </span>
                </button>
              </router-link>
            </template>
            <template v-else>
              <router-link to="/login" class="nav-link" @click.native="close">
                <button
                  type="button"
                  class="relative inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <span>Log In</span>
                </button>
              </router-link>
              <router-link
                to="/register"
                class="nav-link"
                @click.native="close"
              >
                <button
                  type="button"
                  class="relative inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <span>Sign Up</span>
                </button>
              </router-link>
            </template>
          </div>
          <div class="hidden md:ml-4 md:flex-shrink-0 md:flex md:items-center">
            <!-- Profile dropdown -->
            <div class="ml-3 mr-4 relative" v-if="user.loggedIn">
              <div>
                <button
                  @click="showUserMenu = !showUserMenu"
                  class="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  id="user-menu"
                  aria-haspopup="true"
                >
                  <span class="sr-only">Open user menu</span>
                  <i class="fa fa-user-circle user-icon"></i>
                </button>
              </div>
              <transition
                enter-active-class="transition ease-out duration-200 transform"
                enter-from-class="opacity-0 scale-95"
                enter-to-class="opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75 transform"
                leave-from-class="opacity-100 scale-100"
                leave-to-class="opacity-0 scale-95"
              >
                <div
                  v-show="showUserMenu"
                  class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="user-menu"
                >
                  <router-link
                    to="/account"
                    @click.native="close"
                    class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    role="menuitem"
                    >Your Account
                  </router-link>
                  <!-- TODO: add sign out functionality  -->
                  <a
                    href="#"
                    class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    role="menuitem"
                    @click="signOut"
                    >Sign out</a
                  >
                </div>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--
    Mobile menu, toggle classes based on menu state.

    Menu open: "block", Menu closed: "hidden"
  -->
    <div class="block md:hidden" v-show="isMenuOpen">
      <div class="pt-2 pb-3 space-y-1">
        <!-- Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" -->
        <router-link
          to="home"
          @click.native="close"
          class="bg-indigo-50 border-indigo-500 text-indigo-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6"
          >Home</router-link
        >
        <router-link
          to="/help"
          @click.native="close"
          class="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6"
          >Help</router-link
        >
        <router-link
          to="/about"
          @click.native="close"
          class="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6"
          >About</router-link
        >
      </div>
      <div class="pt-2 pb-1 border-t border-gray-200">
        <div class="">
          <template v-if="user.loggedIn">
            <router-link
              :to="{ name: 'host', params: { id: 'new' } }"
              @click.native="close"
              class="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 sm:px-6"
            >
              Start Remote Session
            </router-link>

            <router-link
              to="account"
              @click.native="close"
              class="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 sm:px-6"
              >Your Account</router-link
            >
          </template>
          <template v-else>
            <router-link
              to="/register"
              class="block px-4 py-2 text-base font-medium text-blue-600 hover:text-blue-800 hover:bg-gray-100 sm:px-6"
              @click.native="close"
            >
              Sign Up
            </router-link>

            <router-link
              to="/login"
              class="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6"
              @click.native="close"
            >
              Log In
            </router-link>
          </template>
        </div>
      </div>
    </div>
  </nav>
</template>
<script>
import { mapGetters } from "vuex";
import firebase from "firebase";
export default {
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  data() {
    return {
      isMenuOpen: false,
      showUserMenu: false,
    };
  },
  methods: {
    close: function () {
      this.isMenuOpen = false;
      this.showUserMenu = false;
    },
    signOut: async function () {
      try {
        await this.$auth.logout();
        // Only redirect if we're not already on the home page
        if (this.$route.name !== 'home') {
          await this.$router.push({ name: "home" });
        }
      } catch (error) {
        tis.error = error
        console.error('Logout error:', error);
        // Enhanced Bugsnag logging
        if (window.Bugsnag) {
          Bugsnag.notify(error, {
            severity: 'warning',
            context: 'Logout Attempt',
            metaData: {
              errorType: error.name,
              errorMessage: error.message,
              route: this.$route.name
            }
          });
        }
      }
    },
  },
};
</script>

<style>
.user-icon {
  font-size: 2.4em;
}
</style>
