import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { firebase } from '@firebase/app'
import store from "./store";
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import SimpleAnalytics from "simple-analytics-vue";
import authPlugin from './auth'
import getBrowserLocale from "@/locale.js"
import getPlan from './plan';
import VueImageBrightness from 'vue-image-brightness'
import './styles/index.css'

Vue.use(VueImageBrightness)


Vue.config.productionTip = false

console.log('test:', process.env.VUE_APP_AUTH_DOMAIN);

firebase.initializeApp({
  apiKey: process.env.VUE_APP_API_KEY,
  authDomain: process.env.VUE_APP_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_DATABASE_URL,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_APP_ID
});


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

Vue.use(require('vue-moment'));

Vue.use(authPlugin)

Vue.prototype.$shared = {
  email: "support@cloudemdr.com",
  locale: getBrowserLocale()
}

Bugsnag.start({
  apiKey: 'ae5e8d8da4198ac6b8224d3b2b1e6209',
  plugins: [new BugsnagPluginVue(Vue)],
  releaseStage: process.env.NODE_ENV,
  logger: null,
  onError: function (event) {
    const user = store.getters.user
    const anonymousId = "anonymous"
    if (user && user.loggedIn) {
      event.setUser(user.data.uid)
    } else {
      event.setUser(anonymousId)
    }
  }
})

Vue.use(SimpleAnalytics, { skip: process.env.NODE_ENV !== "production" });

if(process.env.NODE_ENV == "development") {
  console.log("using develop")
} else {
  
}